import React from 'react'
import "./mystyles.scss"
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/font-awesome'
import SEO from '../components/SEO'

export default function leadership() {
    return (
        <Layout>
            <SEO />
            <section className="container has-text-light pb-5">
                <p className="title has-text-primary">Leadership Development</p>
                <div className="columns pb-5">
                    <div className="column">
                        <p className="subtitle has-text-light p-6 is-4">Leadership development expands the capacity of individuals to perform in leadership roles. People in leadership roles facilitate the creation and execution of a company’s strategy through building alignment, winning mindshare and growing their capabilities and those of others.</p>
                    </div>
                    <div className="column">
                        <StaticImage 
                            src="../images/people-seated-on-couch-and-chairs-around-coffee-table.jpg"
                            alt="people seated on couch and chairs around coffee table"
                            placeholder="blurred"
                        />
                    </div>
                </div>
                <div className="columns pb-5">
                    <div className="column">
                        <StaticImage 
                            src="../images/christina-wocintechchat-com-Q80LYxv_Tbs-unsplash.png"
                            alt="group of people seated at table looking at woman at whiteboard"
                            placeholder="blurred"
                        />
                    </div>
                    <div className="column">
                        <p className="py-4 is-size-5">Our leadership development consulting clients have seen culture transformations through powerful programs that are aligned with the vision, mission and strategy of the organization. Our role is to conduct needs analyses to identify and understand where a company’s leaders currently are and where they need to be. We then collaborate on creating learning programs that move the leaders in the right direction.</p>
                        <p className="py-4 is-size-5">These programs are typically instructor-led and delivered in-person and/or virtually. They are customized to the organization, interactive and designed to motivate people to take action.</p>
                    </div>
                </div>
                <div>
                    <p className="subtitle has-text-primary is-size-4">We create custom-designed leadership development solutions for your organization’s context, utilizing an interactive approach that sustains learning and behavior changes and has the greatest impact on performance. Components of our work may include:</p>
                    <div className="columns">
                        <div className="column">
                            <ul>
                                <li className="p-4 is-size-5"><FontAwesomeIcon icon={'clipboard-list'} size="1x" /> Consulting and needs assessment to determine content and approach</li>
                                <li className="p-4 is-size-5"><FontAwesomeIcon icon={['fab', 'leanpub']} size="1x" /> Learning programs aligned with your organization’s mission and vision</li>
                                <li className="p-4 is-size-5"><FontAwesomeIcon icon={'chalkboard-teacher'} size="1x" /> Interactive delivery approaches that ensure learning sticks and improves performance</li>
                                <li className="p-4 is-size-5"><FontAwesomeIcon icon={'expand-arrows-alt'} size="1x" /> Programs designed to enrich capabilities, shift perspectives, and transform cultures</li>
                            </ul>
                        </div>
                        <div className="column">
                            <ul>
                                <li className="p-4 is-size-5"><FontAwesomeIcon icon={'user-friends'} size="1x" /> Integrated program facilitation by subject matter experts, external faculty, leaders as teachers and technology providers</li>
                                <li className="p-4 is-size-5"><FontAwesomeIcon icon={'bullhorn'} size="1x" /> Consultation on effective internal program marketing</li>
                                <li className="p-4 is-size-5"><FontAwesomeIcon icon={'user-check'} size="1x" /> Assistance identifying individual contributors, managers and executives who will most benefit from the experience</li>
                                <li className="p-4 is-size-5"><FontAwesomeIcon icon={'file-invoice-dollar'} size="1x" /> An investment to fit your budget</li>
                            </ul>
                        </div>
                    </div>
                    <p className="pb-5 is-size-4 has-text-primary">The company principal, Judy McHugh, has 25+ years in the leadership development field. She has designed and delivered hundreds of customized programs, using both in-person and virtual classroom environments. We have a large network of instructors and facilitators, as well as partnerships with simulation, action-learning, and technology providers.</p>
                </div>
            </section>
        </Layout>
    )
}
